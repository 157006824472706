.filter {
  margin-bottom: 2rem;

  .inner {
    padding-top: 3rem;
    @include flex-col();
  }

  h1,
  .nav--filters {
    align-self: center;
  }

  h1 {
    margin-bottom: 3rem;
    position: relative;
    @include pseudo-after();

    &:after {
      width: 2rem;
      height: 2rem;
      opacity: 0.001;
      background: url('./../images/global/icon-loader--black.svg') center center no-repeat;
      background-size: 100% auto;
      bottom: -2.5rem;
      @include central-x();
      @include w-c-t();
    }
  }
  
  .subheading {    
    margin-bottom: 3rem;
    text-align: center;
  }

  &--loading h1:after {
    animation: spin-x 1.2s linear infinite;
    opacity: 0.999;
  }

  &__form {
    width: 100%;
    @include flex-col();
  }

  .inner,
  &__form {
    align-items: normal;
  }

  &__open,
  &__clear,
  &__count {
    @include text-button();
  }

  &__open,
  &__clear {
    @include link-underline('transparent', 'black', 'grey-dark');
  }

  &__open {
    align-self: flex-end;
  }

  &__close {
    @include icon-button();
    pointer-events: none;
    position: fixed;
    top: 1.8rem;
    right: calc(#{$gutter-mobile} - 1rem);
    background: var(--white);
    opacity: 0.001;

    @include hover() {
      opacity: 0.25;
    }

    @media screen and (min-width: $portrait) {
      right: calc(#{$gutter-portrait} - 1rem);
    }

    @media screen and (min-width: $landscape) {
      right: calc(#{$gutter-landscape} - 1rem);
    }

    .nav--active--filter & {
      pointer-events: all;
      z-index: 20;
    }

    @include rtl() {
      @include switch-rtl(calc(#{$gutter-mobile} - 1rem));

      @media screen and (min-width: $portrait) {
        @include switch-rtl(calc(#{$gutter-portrait} - 1rem));
      }

      @media screen and (min-width: $landscape) {
        @include switch-rtl(calc(#{$gutter-landscape} - 1rem));
      }
    }
  }

  &__clear {
    color: var(--grey-dark);
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 1rem;
  }

  &__count {
    display: none;
  }

  &__label {
    font-size: 1.3rem;
    border-color: var(--transparent);
  }

  input {
    position: absolute;
  }

  label {
    cursor: pointer;
  }

  &__apply {
    align-self: center;
    margin-top: 8rem;
    align-self: flex-start;

    @media screen and (max-width: 425px) {
      width: 100%;
    }
  }

  @media screen and (max-width: $nav - 1) {
    .nav {
      &-wrapper {
        @include flex-col();
        align-items: normal;
      }

      &--filters {
        width: 100%;

        li {
          padding: 1.5rem 0;

          &:not(:last-of-type) {
            @include pseudo-before();
            position: relative;

            &:before {
              width: 100%;
              position: absolute;
              left: 0;
              bottom: 0;
              border-bottom: 0.1rem solid var(--grey-light);
            }
          }
        }

        .filter__label {
          background-color: var(--transparent);
          border-color: var(--transparent);
          display: block;
          width: 100%;
          background: none;
          text-align: left;
          padding: 1rem 0;

          @include hover() {
            color: var(--black);
          }

          &:active {
            transform: none;
            background: url('./../images/global/icon-tick--black.svg') right center no-repeat;
            background-size: 1.4rem auto;
          }
        }

        input:checked + .filter__label {
          background: url('./../images/global/icon-tick--black.svg') right center no-repeat;
          background-size: 1.4rem auto;

          @include fonts-loaded() {
            font-family: var(--roboto-black);
          }
        }

        @include rtl() {
          .filter__label {
            text-align: right;
          }

          .filter__label:active,
          input:checked + .filter__label {
            background-position: left center;
          }
        }
      }
    }
  }

  @media screen and (min-width: $nav) {
    margin: 0;

    h1 {
      margin-bottom: 4rem;
    }

    .subheading {    
      margin-bottom: 4rem;
    }

    .nav--filters {
      order: 1;
      margin-bottom: 2rem;

      ul {
        display: flex;
      }

      li:not(:last-of-type) {
        margin-right: 2rem;

        @include rtl() {
          @include switch-mar(2, 0);
        }
      }

      input:checked + .filter__label {
        border-color: var(--black);

        @include hover() {
          border-color: var(--grey-dark);
        }
      }
    }

    &__header {
      order: 2;
      justify-content: flex-end;
    }

    &__count {
      margin-right: 3rem;

      @include rtl() {
        @include switch-mar(3, 0);
      }
    }

    &__open,
    &__close,
    &__heading,
    &__apply {
      display: none;
    }

    &__header {
      opacity: 0;
      visibility: hidden;
      transition: opacity 0.2s ease-out;
      @include w-c-o();

      &--active {
        opacity: 1;
        visibility: inherit;
      }
    }

    &__count,
    &__clear {
      font-size: 1rem;
    }

    &__count {
      display: block;
    }
  }
}
