.exit {
    visibility: hidden;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%; 
    height: 100%; 
    overflow: auto; 
    background-color: rgb(0,0,0); 
    background-color: rgba(0,0,0,0.4);
    display: flex;
    justify-content: center;
    will-change: opacity;
    opacity: 0.001;

    &-content {
        position: relative;
        width: $content-mobile;
        max-width: 66.5rem;
        background-color: var(--white);
        padding: $gutter-mobile;
        text-align: center;
        will-change: transform;
        transform: scale(1.1);
        margin: auto;

        @media screen and (min-width: $portrait) {
            padding: $gutter-portrait;
        }

        @media screen and (min-width: $landscape) {
            padding: $gutter-landscape;
        }

        @media screen and (min-width: $desktop) {
            padding: calc(#{$gutter-desktop} - 2rem);
        }
    }

    .icon--close {
        position: absolute;
        top: 1.8rem;
        right: 1.8rem;
    }

    .block-button {
        margin-top: 5rem;
    }

    p {
        margin-top: 2rem;
    }

    .h1 {
        @media screen and (max-width: $portrait - 1) {
            padding: 0 2rem;
        }

        @media screen and (min-width: $landscape) {
            font-size: 5.6rem;
            line-height: 1.07;
        }
    }
}
