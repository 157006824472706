.about {
  @include image-underlay();

  &:last-of-type {
    margin-bottom: 4rem;

    @media screen and (min-width: $landscape) {
      margin-bottom: 8rem;
    }
  }

  .inner {
    width: 100%;
  }

  &__section {
    position: relative;
  }

  &--full .inner {
    padding: 0;
  }

  &--split {
    .inner {
      @include grid();

      @media screen and (min-width: $landscape) {
        grid-gap: 0 $gap-landscape;
      }
    }

    .about__section {
      &--secondary {
        display: none;

        @media screen and (min-width: $portrait) {
          display: block;
          grid-column: 1 / 3;
          @include bleed-left-portrait();
        }

        @media screen and (min-width: $landscape) {
          grid-column: 1 / 5;
          @include bleed-left-landscape();
          width: calc(100% + #{$gutter-landscape} + #{$gap-landscape});
        }

        @media screen and (min-width: $desktop) {
          @include bleed-left-desktop();
          width: calc(100% + #{$gutter-desktop} + #{$gap-landscape});
        }
      }

      &--primary {
        @include full-page-mobile();
        @include bleed-left-mobile();

        @media screen and (min-width: $portrait) {
          grid-column: 3 / 7;
          @include half-grid-portrait();
          margin-left: 0;

          @include rtl() {
            margin-right: 0;
          }
        }

        @media screen and (min-width: $landscape) {
          grid-column: 5 / 13;
          @include half-grid-landscape();
        }

        @media screen and (min-width: $desktop) {
          @include half-grid-desktop();
        }
      }
    }
  }

  &__block {
    min-height: 35rem;
    padding: 3rem 0;
    @include flex-col();
    justify-content: center;
    text-align: center;

    @media screen and (min-width: $portrait) {
      min-height: 45rem;
    }

    @media screen and (min-width: $landscape) {
      min-height: 55rem;
    }

    * {
      position: relative;
    }

    h2 {
      margin-bottom: 4rem;
    }
  }
}
