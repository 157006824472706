.origin {
  padding-top: 3rem;

  &__section {
    @include grid-gaps();

    &:not(:first-of-type) {
      display: none;
    }
  }

  h1 {
    @include light-underline();
    margin-bottom: 3rem;
  }

  &__image,
  &__block {
    margin-top: 4rem;
  }

  &__image {
    background: var(--black);
    height: 30rem;
  }

  &__block {
    > h2,
    > h3,
    > h4 {
      @extend .h2--article;
      margin-top: 3rem;
    }

    p:not(:first-of-type) {
      margin-top: 2rem;
    }
  }

  @media screen and (min-width: $portrait) {
    &__title,
    &__block {
      grid-column: 1 / 4;
    }

    &__image,
    &__block {
      grid-row: 2 / 3;
    }

    &__image {
      grid-column: 4 / 7;
      height: 40rem;
      margin-top: -3rem;
    }
  }

  @media screen and (min-width: $landscape) {
    padding-top: 5rem;

    h1 {
      margin-bottom: 5rem;
    }

    &__title,
    &__block {
      grid-column: 1 / 7;
    }

    &__image {
      grid-column: 7 / 13;
      height: 50rem;
    }
  }
}
