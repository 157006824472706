.banner {
  @include image-underlay(0.8);
  color: unset;
  margin-bottom: 8rem;

  @media screen and (min-width: $portrait) {
    margin: unset;
  }

  .inner {
    position: relative;
    background: var(--black);
  }

  &--expanded {
    .hover ~ * * {
      pointer-events: auto;
    }
  }

  &__block {
    min-height: 30rem;
    padding: 8rem 0;
    @include flex-col();
    justify-content: flex-start;
    text-align: center;

    @media screen and (min-width: $landscape) {
      padding: 12rem 0;
      min-height: 40rem;
    }

    h2 {
      position: relative;
      color: var(--white);
      padding: 0 4rem;
      margin-bottom: 2rem;
      transition: color 0.2s ease-out;
      will-change: color;

      .banner--expanded & {
        color: var(--black);
      }

      & ~ .block-button {
        margin-top: 2rem;
      }
    }
  }

  &__background {
    @include absolute-cover();
    background: var(--grey-lighter);
    height: 0;

    .icon {
      --offset: 1.2rem;
      z-index: 5;
      visibility: hidden;
      opacity: 0;
      position: absolute;
      top: 7.8rem;
      right: calc(#{$gutter-mobile} - var(--offset));

      @media screen and (min-width: $portrait) {
        right: calc(#{$gutter-portrait} - var(--offset));
      }

      @media screen and (min-width: $landscape) {
        top: 10.8rem;
        right: calc(#{$gutter-landscape} - var(--offset));
      }

      @media screen and (min-width: $desktop) {
        right: calc(#{$gutter-desktop} - var(--offset));
      }
    }
  }

  &__form {
    display: none;

    p,
    .form-list,
    label,
    button {
      visibility: hidden;
      opacity: 0;
      transform: translateY(1.5rem);
    }

    .form-group {
      width: 100%;
    }

    .form-list,
    .form-input {
      text-align: left;
    }

    .form-list {
      position: relative;
    }
  }

  @include rtl() {
    &__background .icon {
      @include switch-rtl(calc(#{$gutter-mobile} - var(--offset)));

      @media screen and (min-width: $portrait) {
        @include switch-rtl(calc(#{$gutter-portrait} - var(--offset)));
      }

      @media screen and (min-width: $landscape) {
        @include switch-rtl(calc(#{$gutter-landscape} - var(--offset)));
      }

      @media screen and (min-width: $desktop) {
        @include switch-rtl(calc(#{$gutter-desktop} - var(--offset)));
      }
    }

    &__form {
      .form-list,
      .form-input {
        text-align: right;
      }
    }
  }
}
