.capture {
  background: var(--grey-lighter);
  padding: 5rem 0;
  position: relative;
  margin-top: 4rem;

  @media screen and (min-width: $landscape) {
    padding: 6rem 0;
    margin-top: 8rem;
  }

  @media screen and (min-width: $nav) {
    margin-top: calc(8rem - calc(var(--header) * 0.1rem));
  }

  h2,
  p {
    text-align: center;
  }

  h2 {
    margin-bottom: 2rem;
  }
}
