.stories {
  .inner {
    @include flex-col();
  }

  &__grid {
    @include grid();
    @include full-page();
    background: var(--grey-light);
  }

  button {
    margin: 4rem auto 0;

    @media screen and (min-width: $landscape) {
      margin: 5rem auto 3rem;
    }
  }
}

.story {
  position: relative;
  @include image-underlay();
  min-height: 30rem;
  overflow: hidden;

  &--blank {
    background: var(--blank);
    @include pseudo-after();
    text-indent: -99999rem;

    &:before {
      content: none;
    }

    &:after {
      @include absolute-cover();
      background: url('./../images/global/logo-umbro--blue.svg') center center no-repeat;
      background-size: 8rem auto;
    }

    @media screen and (max-width: $portrait - 1) {
      display: none;
    }
  }

  &--animating {
    &__image {
      background: var(--transparent);
    }
  }

  &__tile {
    @include absolute-cover();
    @include flex-col();
    align-items: flex-start;
    justify-content: flex-end;
    @include pseudo-before();

    &:before,
    p,
    .block-button {
      display: none;
      //opacity: 0.001;
      //transition: opacity 0.3s ease;
      //@include w-c-o();
    }

    &:before {
      @include absolute-cover();
      background-image: linear-gradient(180deg, var(--transparent), var(--transparent) 25%, var(--navy));
    }

    * {
      position: relative;
    }

    .h4 {
      @include link-underline('transparent', 'white', 'grey-dark', 0rem);

      &:before {
        content: none;
      }

      &:after {
        content: '\A';
        white-space: pre;
        position: relative;
        height: 0;
      }
    }

    p {
      margin-top: 1rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
    }

    .block-button {
      margin-top: 1rem;
    }

    &:hover {
      &:before,
      p,
      .block-button {
        display: inline-block
      }

      .h4 {
        transform: translateY(0);
        transition-delay: 0.25s;
        @include link-underline-hover();
      }

      p,
      .block-button {
        transition-delay: 0.2s;
      }

      .story__block {
        transform: translateY(0);
      }
    }

    &:active .h4 {
      transition-delay: 0s;
      @include link-underline-active();
    }
  }

  &__block {
    width: 100%;
    position: relative;
    padding: 3rem;
    transition: transform 0.3s ease;
    will-change: transform;
    @include w-c-t();

    &--description.story__block--button {
      transform: translateY(8.4rem);
    }

    &--description {
      transform: translateY(3.2rem);
    }

    &--button {
      transform: translateY(5.2rem);
    }
  }

  @media screen and (min-width: $portrait) {
    &:nth-of-type(3n + 14) {
      grid-column: 1 / 3;
    }

    &:nth-of-type(3n + 15) {
      grid-column: 3 / 5;
    }

    &:nth-of-type(3n + 16) {
      grid-column: 5 / 7;
    }

    &:nth-of-type(1),
    &:nth-of-type(9) {
      grid-column: 1 / 4;
    }

    &:nth-of-type(2),
    &:nth-of-type(10) {
      grid-column: 4 / 7;
    }

    &:nth-of-type(3),
    &:nth-of-type(6),
    &:nth-of-type(11) {
      grid-column: 1 / 3;
    }

    &:nth-of-type(4),
    &:nth-of-type(7),
    &:nth-of-type(12) {
      grid-column: 3 / 5;
    }

    &:nth-of-type(5),
    &:nth-of-type(8),
    &:nth-of-type(13) {
      grid-column: 5 / 7;
    }

    &[data-story-filter="3"],
    &[data-story-filter="4"],
    &[data-story-filter="5"],
    &[data-story-filter="6"],
    &[data-story-filter="7"],
    &[data-story-filter="8"],
    &[data-story-filter="9"],
    &[data-story-filter="10"],
    &[data-story-filter="11"],
    &[data-story-filter="12"],
    &[data-story-filter="13"] {
      &:nth-of-type(13n + 11),
      &:nth-of-type(13n + 12),
      &:nth-of-type(13n + 13) {
        display: none;
      }
    }

    &[data-story-filter="5"],
    &[data-story-filter="6"],
    &[data-story-filter="7"],
    &[data-story-filter="8"],
    &[data-story-filter="9"],
    &[data-story-filter="10"],
    &[data-story-filter="11"],
    &[data-story-filter="12"],
    &[data-story-filter="13"] {
      &:nth-of-type(13n + 9),
      &:nth-of-type(13n + 10) {
        display: none;
      }
    }

    &[data-story-filter="8"],
    &[data-story-filter="9"],
    &[data-story-filter="10"],
    &[data-story-filter="11"],
    &[data-story-filter="12"],
    &[data-story-filter="13"] {
      &:nth-of-type(13n + 6),
      &:nth-of-type(13n + 7),
      &:nth-of-type(13n + 8) {
        display: none;
      }
    }

    &[data-story-filter="11"],
    &[data-story-filter="12"],
    &[data-story-filter="13"] {
      &:nth-of-type(13n + 3),
      &:nth-of-type(13n + 4),
      &:nth-of-type(13n + 5) {
        display: none;
      }
    }

    &[data-story-more="3"],
    &[data-story-more="4"],
    &[data-story-more="5"],
    &[data-story-more="6"],
    &[data-story-more="7"],
    &[data-story-more="8"],
    &[data-story-more="9"],
    &[data-story-more="10"],
    &[data-story-more="11"],
    &[data-story-more="12"] {
      &:nth-of-type(12n + 11),
      &:nth-of-type(12n + 12),
      &:nth-of-type(12n + 13) {
        display: none;
      }
    }

    &[data-story-more="6"],
    &[data-story-more="7"],
    &[data-story-more="8"],
    &[data-story-more="9"],
    &[data-story-more="10"],
    &[data-story-more="11"],
    &[data-story-more="12"] {
      &:nth-of-type(12n + 8),
      &:nth-of-type(12n + 9),
      &:nth-of-type(12n + 10) {
        display: none;
      }
    }

    &[data-story-more="9"],
    &[data-story-more="10"],
    &[data-story-more="11"],
    &[data-story-more="12"] {
      &:nth-of-type(12n + 5),
      &:nth-of-type(12n + 6),
      &:nth-of-type(12n + 7) {
        display: none;
      }
    }
  }

  @media screen and (min-width: $landscape) {
    &:nth-of-type(4n + 14) {
      grid-column: 1 / 4;
    }

    &:nth-of-type(4n + 15) {
      grid-column: 4 / 7;
    }

    &:nth-of-type(4n + 16) {
      grid-column: 7 / 10;
    }

    &:nth-of-type(4n + 17) {
      grid-column: 10 / 13;
    }

    &:nth-of-type(1),
    &:nth-of-type(2) {
      min-height: 55rem;
    }

    &:nth-of-type(1) {
      grid-column: 1 / 7;
    }

    &:nth-of-type(2) {
      grid-column: 7 / 13;
    }

    &:nth-of-type(3),
    &:nth-of-type(10) {
      grid-column: 1 / 4;
    }

    &:nth-of-type(4),
    &:nth-of-type(11) {
      grid-column: 4 / 7;
    }

    &:nth-of-type(5),
    &:nth-of-type(12) {
      grid-column: 7 / 10;
    }

    &:nth-of-type(6),
    &:nth-of-type(13) {
      grid-column: 10 / 13;
    }

    &:nth-of-type(7),
    &:nth-of-type(8),
    &:nth-of-type(9) {
      min-height: 40rem;
    }

    &:nth-of-type(7) {
      grid-column: 1 / 5;
    }

    &:nth-of-type(8) {
      grid-column: 5 / 9;
    }

    &:nth-of-type(9) {
      grid-column: 9 / 13;
    }

    &[data-story-filter],
    &[data-story-more] {
      &:nth-of-type(odd),
      &:nth-of-type(even) {
        display: block;
      }
    }

    &[data-story-filter="4"],
    &[data-story-filter="5"],
    &[data-story-filter="6"],
    &[data-story-filter="7"],
    &[data-story-filter="8"],
    &[data-story-filter="9"],
    &[data-story-filter="10"],
    &[data-story-filter="11"],
    &[data-story-filter="12"],
    &[data-story-filter="13"] {
      &:nth-of-type(13n + 10),
      &:nth-of-type(13n + 11),
      &:nth-of-type(13n + 12),
      &:nth-of-type(13n + 13) {
        display: none;
      }
    }

    &[data-story-filter="7"],
    &[data-story-filter="8"],
    &[data-story-filter="9"],
    &[data-story-filter="10"],
    &[data-story-filter="11"],
    &[data-story-filter="12"],
    &[data-story-filter="13"] {
      &:nth-of-type(13n + 7),
      &:nth-of-type(13n + 8),
      &:nth-of-type(13n + 9) {
        display: none;
      }
    }

    &[data-story-filter="11"],
    &[data-story-filter="12"],
    &[data-story-filter="13"] {
      &:nth-of-type(13n + 3),
      &:nth-of-type(13n + 4),
      &:nth-of-type(13n + 5),
      &:nth-of-type(13n + 6) {
        display: none;
      }
    }

    &[data-story-filter="12"],
    &[data-story-filter="13"] {
      &:after {
        background-size: 23rem auto;
      }
    }

    &[data-story-more="4"],
    &[data-story-more="5"],
    &[data-story-more="6"],
    &[data-story-more="7"],
    &[data-story-more="8"],
    &[data-story-more="9"],
    &[data-story-more="10"],
    &[data-story-more="11"],
    &[data-story-more="12"] {
      &:nth-of-type(12n + 10),
      &:nth-of-type(12n + 11),
      &:nth-of-type(12n + 12),
      &:nth-of-type(12n + 13) {
        display: none;
      }
    }

    &[data-story-more="8"],
    &[data-story-more="9"],
    &[data-story-more="10"],
    &[data-story-more="11"],
    &[data-story-more="12"] {
      &:nth-of-type(12n + 6),
      &:nth-of-type(12n + 7),
      &:nth-of-type(12n + 8),
      &:nth-of-type(12n + 9) {
        display: none;
      }
    }

    &__block {
      padding: 4rem;
    }
  }
}
