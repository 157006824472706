.article {
  > h2,
  > h3,
  > h4,
  form {
    @extend .h2--article;
    margin: 3rem 0 1.5rem;

    & + p,
    & + ul {
      margin-top: 0;
    }
  }

  p,
  > ul {
    margin: 2rem 0;
  }

  p a {
    color: var(--red);
    @include underlined-link();
  }

  > ul {
    @extend .diamond-list;
    margin: 2rem 0 3rem;
  }

  > .block-button:last-child {
    margin-top: 3rem;
  }

  @media screen and (min-width: $portrait) {
    margin: 0 auto;
    max-width: 84rem;
  }
}
