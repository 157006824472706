.results {
  padding: 2rem 0 10rem;

  &__search,
  h2,
  &-items {
    margin-bottom: 4rem;
  }

  h1 {
    margin-bottom: 2rem;
  }

  h2 {
    color: var(--grey-dark);
    letter-spacing: 0.1rem;

    @include fonts-loaded() {
      font-family: var(--roboto-medium);
    }
  }

  &-item {
    @include light-underline('grey-light');
    padding: 1.6rem 0 1.5rem;

    &:first-of-type {
      padding-top: 0;
    }

    a {
      @include hover() {
        p {
          @include link-underline-hover();
        }
      }

      &:active h3 {
        @include link-underline-active();
      }
    }
  }

  &__image {
    width: 8rem;
    height: 8rem;
    margin-bottom: 1.5rem;
    background: var(--black);
  }

  &__block {
    span {
      font-size: 1.3rem;
      line-height: 1.46;
      letter-spacing: 0;
      display: block;
    }

    h3 {
      padding: 0.5rem 0;
      @include text-button();
      @include link-underline('transparent', 'black', 'grey-dark', 0.3rem);

      &:before,
      &:after {
        content: none;
      }

      strong {
        @include fonts-loaded() {
          font-family: var(--roboto-bold);
        }
      }
    }
  }

  @media screen and (min-width: $portrait) {
    &-item {
      padding: 2rem 0;

      a {
        display: flex;
        align-items: flex-end;
        justify-content: flex-start;
      }
    }

    &__image {
      margin: 0 2rem 0 0;
    }

    &__block span {
      margin-bottom: 0.5rem;
    }

    .nav--pagination {
      margin-top: 4rem;
    }
  }

  @media screen and (min-width: $landscape) {
    padding-bottom: 12rem;

    .inner {
      padding-left: calc(#{$gutter-landscape} + 11.8rem);
    }

    &__search {
      display: none;
    }

    &__search,
    h2,
    &-items {
      margin-bottom: 5rem;
    }
  }

  @media screen and (min-width: $desktop) {
    .inner {
      padding-left: calc(#{$gutter-desktop} + 11.8rem);
    }
  }

  @media screen and (min-width: $max) {
    .inner {
      padding-left: calc(#{$gutter-desktop} + 12.8rem);
    }
  }

  @include rtl() {
    @media screen and (min-width: $portrait) {
      &__image {
        @include switch-mar(2rem, 0);
      }
    }

    @media screen and (min-width: $landscape) {
      .inner {
        @include switch-pad($gutter-landscape, calc(#{$gutter-landscape} + 11.8rem));
      }
    }

    @media screen and (min-width: $desktop) {
      .inner {
        @include switch-pad($gutter-desktop, calc(#{$gutter-desktop} + 11.8rem));
      }
    }

    @media screen and (min-width: $max) {
      .inner {
        @include switch-pad($gutter-desktop, calc(#{$gutter-desktop} + 12.8rem));
      }
    }
  }
}
