.recommended {
  position: relative;
  background: var(--white);
  padding-bottom: 6.5rem;
  @include image-underlay(0.8, 'black');

  &__grid {
    @include grid();
    @include full-page();
    @include bleed-left();
  }

  h2 {
    @include light-underline();
    margin-bottom: 4rem;
  }

  &-product,
  &-article {
    display: block;

    &:not(:last-of-type) {
      margin-bottom: 3rem;
    }

    @include hover() {
      p {
        @include link-underline-hover();
      }
    }

    &:active p {
      @include link-underline-active();
    }
  }

  &__image {
    height: 30rem;
    position: relative;

    img {
      @include absolute-cover();
    }
  }

  &__block {
    padding: 2rem 3rem;

    .small,
    p {
      @include fonts-loaded() {
        font-family: var(--roboto-medium);
      }
    }

    .small {
      color: var(--grey-darkest);
      margin-bottom: 1rem;
    }

    p {
      padding: 0.5rem 0;
      @include link-underline('transparent', 'black', 'grey-dark', 0.3rem);
    }
  }

  @media screen and (min-width: $portrait) {
    padding-top: 8rem;
    position: relative;
    z-index: 10;

    &__block {
      padding-left: 0;
    }

    &-product,
    &-article {
      &:not(:last-of-type) {
        margin-bottom: 0;
      }

      &:nth-of-type(1) {
        grid-column: 1 / 3;

        .recommended__block {
          padding-left: $gutter-portrait;
        }
      }

      &:nth-of-type(2) {
        grid-column: 3 / 5;
      }

      &:nth-of-type(3) {
        grid-column: 5 / 7;

        .recommended__block {
          padding-right: $gutter-portrait;
        }
      }
    }
  }

  @media screen and (min-width: $landscape) {
    padding-top: 12rem;
    padding-bottom: 8.5rem;

    &__block {
      padding-right: $gap-landscape;
    }

    &-product,
    &-article {
      &:nth-of-type(1) {
        grid-column: 1 / 5;

        .recommended__block {
          padding-left: $gutter-landscape;
        }
      }

      &:nth-of-type(2) {
        grid-column: 5 / 9;
      }

      &:nth-of-type(3) {
        grid-column: 9 / 13;

        .recommended__block {
          padding-right: $gutter-landscape;
        }
      }
    }
  }

  @media screen and (min-width: $desktop) {
    &-product,
    &-article {
      &:nth-of-type(1) {
        .recommended__block {
          padding-left: $gutter-desktop;
        }
      }

      &:nth-of-type(3) {
        .recommended__block {
          padding-right: $gutter-desktop;
        }
      }
    }
  }

  @include rtl() {
    @media screen and (min-width: $portrait) {
      &__block {
        @include switch-pad(3, 0);
      }

      &-product,
      &-article {
        &:nth-of-type(1) {
          .recommended__block {
            @include switch-pad(0, $gutter-portrait);
          }
        }

        &:nth-of-type(3) {
          .recommended__block {
            @include switch-pad($gutter-portrait, 0);
          }
        }
      }
    }

    @media screen and (min-width: $landscape) {
      &__block {
        @include switch-pad($gap-landscape, 0);
      }

      &-product,
      &-article {
        &:nth-of-type(1) {
          .recommended__block {
            @include switch-pad(0, $gutter-landscape);
          }
        }

        &:nth-of-type(3) {
          .recommended__block {
            @include switch-pad($gutter-landscape, 0);
          }
        }
      }
    }

    @media screen and (min-width: $desktop) {
      &-product,
      &-article {
        &:nth-of-type(1) {
          .recommended__block {
            @include switch-pad(0, $gutter-desktop);
          }
        }

        &:nth-of-type(3) {
          .recommended__block {
            @include switch-pad($gutter-desktop, 0);
          }
        }
      }
    }
  }
}
