.product {
  margin-bottom: 0;

  &__prompt {
    position: absolute;
    top: 36.4rem;
    @include central-x();
    color: var(--white);
    font-size: 1.3rem;
    letter-spacing: 0.1rem;
    text-align: center;
    width: calc(100% - calc(#{$gutter-mobile} * 2));
    z-index: 5;
    pointer-events: none;

    @include fonts-loaded() {
      font-family: var(--roboto-medium);
    }
  }

  &__competition {
    padding: 3rem;
    margin: -6rem 3rem 6rem;
    background: rgba(255, 255, 255, 0.9);
    border: 0.1rem solid var(--grey-light);
    @include flex-col();
    text-align: center;
    z-index: 55;
    position: relative;

    h4 {
      padding-top: 0.5rem;
      margin-bottom: 2rem;
    }
  }

  &__colour {
    position: relative;
    @include icon-button();
    @include pseudo-blocks();

    &:before,
    &:after {
      width: 3rem;
      height: 3rem;
      @include central();
    }

    &:before {
      border: 0.3rem solid var(--grey-light);
      opacity: 0.001;
      transition: opacity 0.2s ease;
      @include w-c-o();
    }

    &--active:before {
      opacity: 0.999;
    }

    &:after {
      background-color: var(--swatch);
    }

    &--light:after {
      box-shadow: inset 0 0 0 0.1rem var(--grey-darker);
    }

    @include hover() {
      &:after {
        opacity: 0.75;
      }
    }

    &:active:after {
      transform: translate(-50%, -50%) scale(0.98);
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .nav {
      order: 2;
    }

    .block-button--outline-black {
      order: 1;
    }
  }

  &-slides {
    position: relative;
  }

  &-slide {
    @include grid-gaps();
    align-items: center;
    position: relative;
  }

  &__images {
    min-height: 40rem;
    background: var(--black);
    position: relative;
    @include pseudo-after();
  }

  &__image,
  &__image img {
    @include absolute-cover();
  }

  &__image {
    background: var(--black);

    img {
      opacity: 0.9;
    }
  }

  &__block {
    position: relative;
    padding: 4rem 0;
    @include pseudo-before();

    &:before {
      background: var(--white);
      @include absolute-cover();
      @include full-page-mobile();
      @include bleed-left-mobile();
      height: calc(100% + 8rem);
    }

    * {
      position: relative;
    }

    .h1--article,
    h3,
    .product__colour,
    p,
    .icon,
    .block-button--outline-black {
      opacity: 0;
      visibility: hidden;
      transform: translateY(1.5rem);
    }

    .h1--article {
      margin: 2rem 0 3rem;
      padding-bottom: 2rem;
      position: relative;

      &__line {
        transform: scale(0);
        transform-origin: left center;
        width: 100%;
        position: absolute;
        left: 0;
        bottom: 0;
        border-bottom: 0.1rem solid var(--grey-dark);

        @include rtl() {
          transform-origin: right center;
        }
      }

      + .block-button--outline-black {
        margin: 0;
      }
    }

    .nav {
      &--colour {
        margin-bottom: 3rem;

        ul {
          margin-left: -0.7rem;
        }
      }

      &--social {
        margin-top: 1rem;
      }
    }

    p:not(:first-of-type) {
      margin-top: 2rem;
    }

    // needed to override the inline style added by GSAP in the product slider
    .icon:hover {
      opacity: 0.75!important;
    }

    .nav--social,
    .block-button--outline-black {
      margin-top: 3rem;
    }

    .nav--social ul {
      margin: 0 0 0 -1rem;
    }

    .product__actions--button .nav--social ul {
      margin: 0 -1rem 0 0;
    }

    @include rtl() {
      .nav--social ul {
        margin: 0 -1rem 0 0;
      }

      .product__actions--button .nav--social ul {
        margin: 0 0 0 -1rem;
      }
    }

    // needed to override the inline style added by GSAP in the product slider
    .block-button--outline-black:active {
      transform: scale(0.98)!important;
    }
  }

  @media screen and (max-width: $portrait - 1) {
    .inner {
      padding: 0;
    }

    &__block {
      padding-left: $gutter-mobile;
      padding-right: $gutter-mobile;
      padding-bottom: 8rem;
    }
  }

  @media screen and (min-width: $portrait) {
    padding-bottom: calc(var(--header) * 0.1rem);
    position: relative;
    z-index: 5;

    &__prompt,
    &__competition {
      width: calc(50vw - calc(#{$gap-mobile} / 2) - 4rem);
    }

    &__prompt {
      position: fixed;
      top: auto;
      bottom: calc(calc(var(--banner) * 0.1rem) + 4rem);
      left: 2rem;
      transform: none;
      display: none;

      @include rtl() {
        @include switch-ltr(2);
      }

      &--active {
        display: block;
      }
    }

    &__competition {
      padding: 2rem;
      margin: calc(calc(calc(var(--banner) * 0.1rem) + 6rem) * -1) 0 6rem calc(calc(#{$gutter-portrait} - 2rem) * -1);
      flex-direction: row;
      justify-content: center;

      h4 {
        display: inline-block;
        margin: 0 3rem 0 0;
      }

      @include rtl() {
        @include switch-mar(0, calc(calc(#{$gutter-portrait} - 2rem) * -1));

        h4 {
          @include switch-mar(3, 0);
        }
      }
    }

    &-slide {
      margin-bottom: 0;
      padding-bottom: 4rem;

      &:last-of-type {
        padding-bottom: 0;
      }

      // needed to vertically centre the block when it gets pinned by GSAP
      &:not(:first-of-type) .product__block {
        top: calc(var(--header) * 0.1rem)!important;
      }

      // needed to allow smooth unpinning by GSAP for the last slide on desktop
      &:last-of-type .product__block {
        transform: none!important;
        top: 0!important;
        padding-top: calc(calc(var(--header) * 0.1rem) + 4rem)!important;
        background: var(--transparent);
      }
    }

    &__images,
    &__block {
      min-height: calc(100vh - calc(var(--header) * 0.1rem));
    }

    &-slide > .pin-spacer:first-child,
    &__images {
      grid-column: 1 / 4;
      @include half-grid-portrait();
      @include bleed-left-portrait();
    }

    .pin-spacer:last-of-type,
    &__block {
      grid-column: 4 / 7;
    }

    .pin-spacer {
      &-trigger {
        &-0:last-of-type {
          z-index: 50;
        }

        &-1:last-of-type {
          z-index: 45;
        }

        &-2:last-of-type {
          z-index: 40;
        }

        &-3:last-of-type {
          z-index: 35;
        }

        &-4:last-of-type {
          z-index: 30;
        }

        &-5:last-of-type {
          z-index: 25;
        }

        &-6:last-of-type {
          z-index: 20;
        }

        &-7:last-of-type {
          z-index: 15;
        }

        &-8:last-of-type {
          z-index: 10;
        }
      }
    }

    &__block {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: flex-start;
      background: var(--white);
      transform: translateX(200vw);

      .product-slide--first &,
      .product-slide--active & {
        transform: translateX(0)!important;
      }

      &:before {
        content: none;
      }

      h3,
      .h1--article,
      .nav--colour,
      p {
        width: 100%;
      }
    }
  }

  @media screen and (min-width: $landscape) {
    padding-top: 1rem;

    &__prompt,
    &__competition {
      width: calc(calc(#{$col-landscape} * 8) + calc(#{$gap-landscape} * 7) - 4rem);
    }

    &__prompt {
      left: calc(#{$gutter-landscape} + 2rem);

      @include rtl() {
        @include switch-ltr(calc(#{$gutter-landscape} + 2rem));
      }
    }

    &__competition {
      margin-left: 2rem;

      @include rtl() {
        @include switch-mar(0, 2);
      }

      h4 {
        font-size: 2.8rem;
        line-height: 1.2;
      }
    }

    &-slide > .pin-spacer:first-child,
    &__images {
      grid-column: 1 / 9;
      width: 100%;
      margin: 0;

      @include rtl() {
        margin: 0;
      }
    }

    .pin-spacer:last-of-type,
    &__block {
      grid-column: 9 / 13;
    }

    &__block {
      .nav--social,
      .block-button--outline-black {
        margin-top: 4rem;
      }
    }
  }

  @media screen and (min-width: $desktop) {
    &__prompt,
    &__competition {
      width: calc(calc(#{$col-desktop} * 8) + calc(#{$gap-landscape} * 7) - 4rem);
    }

    &__prompt {
      left: calc(#{$gutter-desktop} + 2rem);

      @include switch-ltr(calc(#{$gutter-desktop} + 2rem));
    }
  }

  @media screen and (min-width: $max) {
    &__prompt,
    &__competition {
      width: 85.3rem;
    }

    &__prompt {
      left: calc(calc(calc(100vw - #{$max}) / 2) + #{$gutter-desktop} + 2rem);

      @include switch-ltr(calc(calc(calc(100vw - #{$max}) / 2) + #{$gutter-desktop} + 2rem));
    }
  }
}
