.alt-image-with-text {
  max-width: calc(#{$max} - calc(#{$gutter-desktop} * 2));
  @include bleed-article-to-gutter();
  padding: 4rem 0;

  @media screen and (min-width: $landscape) {
    padding: 6rem 0;
    display: grid;
    grid-gap: 6rem;
    grid-template-columns: repeat(2, minmax(1rem, 1fr));
    align-items: center;
  }

  article,
  picture {
    grid-row: 1;
  }

  article { 
    grid-column: 2;
  }

  picture {
    grid-column: 1;
    height: 40rem;

    @media screen and (max-width: $landscape) {
      margin-top: 4rem;
    }
  }

  &--right {
    article { 
      grid-column: 1;
    }
  
    picture {
      grid-column: 2;
    }
  }

  &__body { 
    margin-top: 4rem;

    @media screen and (min-width: $landscape) {
      margin-top: 6rem;
    }
  }
}