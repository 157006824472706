footer {
  background: var(--black);
  color: var(--white);

  .inner {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .icon--umbro {
    width: 4.5rem;
    margin-bottom: 4rem;
  }

  .navs {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 4rem $gap-mobile;
  }

  .nav--form,
  .nav--social,
  .small-print {
    grid-column: 1 / 3;
  }

  .nav {
    &__item {
      font-size: 1.2rem;
      padding: 1.4rem 0;
      @include link-underline('transparent', 'white', 'grey-dark', 1.15rem);

      &:before,
      &:after {
        content: none;
      }

      &--active {
        @include link-underline-colours('white', 'grey-dark', 'red');
      }
    }

    .h6 {
      margin-bottom: 1rem;
    }

    &--form {
      .h6 {
        margin-bottom: 1.6rem;
      }

      .form-single,
      .form-input--email {
        margin-top: 0;
      }

      .block-button[type="submit"] {
        margin-top: 1rem;
      }
    }

    &--explore,
    &--stories {
      ul {
        padding: 1rem 0;
      }

      li:not(:last-of-type) {
        margin-bottom: 2rem;
      }
    }
  }

  @media screen and (min-width: $portrait) {
    position: relative;
    z-index: 10;
  }

  @media screen and (min-width: $landscape) {
    .inner {
      @media screen and (min-width: $landscape) {
        padding-top: 6rem;
        padding-bottom: 6rem;
      }
    }

    .navs {
      grid-template-columns: repeat(4, 1fr);
      padding-bottom: 2rem;
      position: relative;
    }

    .nav,
    .small-print {
      grid-row: 1 / 2;
    }

    .nav {
      &--form {
        grid-column: 4 / 5;

        .h6 {
          margin-bottom: 1rem;
        }
      }

      &--social {
        grid-column: 3 / 4;

        .h6 {
          margin-bottom: 2rem;
        }
      }

      &--explore {
        grid-column: 2 / 3;
      }

      &--stories {
        grid-column: 1 / 2;
      }
    }

    .small-print {
      grid-row: unset;
      grid-column: unset;
      text-align: right;
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }
}
