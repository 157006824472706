.video-with-text {
  display: flex;
  flex-direction: column;
  padding: 4rem 0;

  @media screen and (min-width: $landscape) {
    padding: 6rem 0;
  }

  p.video-with-text__subtext {
    max-width: 100rem;
    margin-top: 5rem;
  }

  &__video {
    margin: 4rem 0 2rem;
    background: var(--grey-light);
    position: relative;

    &__image {
      height: 35rem;
    }

    &__subtext p {
      max-width: 95rem;
    }

    @media screen and (min-width: $portrait) {
      &__image {
        height: 50rem;
      }
    }

    @media screen and (min-width: $landscape) {
      margin-bottom: 4rem;

      &__image {
        height: 65rem;
      }
    }
  }

  &__iframe {
    opacity: 0.001;
    visibility: hidden;
    @include absolute-cover();

    iframe {
      width: 100%;
      height: 100%;
      pointer-events: auto;
    }
  }

  a {
    margin-top: 4rem;
    align-self: flex-end;
  }
}