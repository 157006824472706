.slider {
  position: relative;
  overflow: hidden;

  &__slides {
    display: flex;
  }

  &__slide {
    flex-shrink: 0;
    width: 100%;

    &:focus {
      outline: 0;
    }
  }

  @media screen and (min-width: $portrait) {
    &__slide {
      width: 50%;
    }
  }

  @media screen and (min-width: $landscape) {
    &__slide {
      width: 33.33%;
    }
  }
}
