.more {
  position: relative;

  &__slider {
    @include full-page();
    @include bleed-left();
  }

  .slider {
    --offset: #{$gutter-mobile};
    padding-left: var(--offset);

    &__button {
      bottom: auto;
      top: 25.8rem;
    }

    &__slide {
      width: 22rem;

      @include hover() {
        p {
          @include link-underline-hover();
        }
      }

      &:active p {
        @include link-underline-active();
      }
    }

    @media screen and (min-width: $portrait) {
      --offset: #{$gutter-portrait};
    }

    @media screen and (min-width: $landscape) {
      --offset: #{$gutter-landscape};
    }

    @media screen and (min-width: $desktop) {
      --offset: #{$gutter-desktop};
    }
  }

  .recommended__block {
    padding-left: 0;
  }

  @include rtl() {
    .slider {
      @include switch-pad(0, var(--offset));
    }

    .recommended__block {
      @include switch-pad(3rem, 0);
    }
  }
}
