$min: 320px;
$portrait: 800px;
$landscape: 1100px;
$nav: 1300px;
$desktop: 1400px;
$max: 1600px;

$gutter-mobile: 3rem;
$gutter-portrait: 6rem;
$gutter-landscape: 8rem;
$gutter-desktop: 12rem;

$gap-mobile: 3rem;
$gap-landscape: 4rem;

$col-mobile: calc((100vw - $gap-mobile - calc(#{$gutter-mobile} * 2)) / 2);
$col-portrait: calc((100vw - calc(#{$gap-mobile} * 5) - calc(#{$gutter-portrait} * 2)) / 6);
$col-landscape: calc((100vw - calc(#{$gap-landscape} * 11) - calc(#{$gutter-landscape} * 2)) / 12);
$col-desktop: calc((100vw - calc(#{$gap-landscape} * 11) - calc(#{$gutter-desktop} * 2)) / 12);

$content-mobile: calc(100vw - calc(#{$gutter-mobile} * 2));
$content-portrait: calc(100vw - calc(#{$gutter-portrait} * 2));
$content-landscape: calc(100vw - calc(#{$gutter-landscape} * 2));

$offset-portrait: calc(100vw - 84rem - calc(#{$gutter-portrait} * 2));
$offset-landscape: calc(100vw - 84rem - calc(#{$gutter-landscape} * 2));
$offset-desktop: calc(100vw - 84rem - calc(#{$gutter-desktop} * 2));

:root {
  --white: #ffffff;
  --grey-lighter: #f7f7f7;
  --grey-light: #d8d8d8;
  --grey-dark: #9b9b9b;
  --grey-darker: #979797;
  --grey-darkest: #757575;
  --black: #000000;
  --navy: #12131f;
  --blank: #2e2f3a;
  --red: #c32100;
  --transparent: rgba(255, 255, 255, 0);

  --roboto-black: 'roboto-black', 'Helvetica', Helvetica, Arial, 'Lucida Grande', sans-serif;
  --roboto-bold: 'roboto-bold', 'Helvetica', Helvetica, Arial, 'Lucida Grande', sans-serif;
  --roboto-medium: 'roboto-medium', 'Helvetica', Helvetica, Arial, 'Lucida Grande', sans-serif;
  --roboto-regular: 'roboto-regular', 'Helvetica', Helvetica, Arial, 'Lucida Grande', sans-serif;
  --italian: 'italian-plate-no2-extrabold', 'Helvetica', Helvetica, Arial, 'Lucida Grande', sans-serif;

  --portrait: #{$portrait};
  --landscape: #{$landscape};
  --nav: #{$nav}
}
