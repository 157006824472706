.timeline {
  padding-bottom: 8rem;

  &-controls {
    margin: 4rem 0;
    @include light-underline();
    padding-bottom: 0;
  }

  &-decades,
  &-decade__years ul {
    display: flex;
    overflow: auto;
    -webkit-overflow-scrolling: touch;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &-decades {
    padding-bottom: 8.5rem;
    @include full-page-mobile();
    @include bleed-left-mobile();
  }

  &-decade {
    position: relative;
    margin: 0 1.5rem;

    &:first-of-type {
      padding-left: calc(#{$gutter-mobile} - 2.5rem);

      .timeline-decade__years {
        left: calc(50% + 0.25rem);
      }
    }

    &:last-of-type {
      padding-right: calc(calc(var(--offset) * 0.1rem) + 0.5rem);

      .timeline-decade__years {
        left: calc(50% - calc(calc(calc(var(--offset) * 0.1rem) - 2rem) / 2));
      }
    }

    &:not(:first-of-type) {
      @include pseudo-before();

      &:before {
        width: 3rem;
        border-bottom: 0.2rem solid var(--grey-light);
        top: 1.8rem;
        left: -3rem;
      }
    }

    @include rtl() {
      &:first-of-type {
        @include switch-pad(0, calc(#{$gutter-mobile} - 2.5rem));

        .timeline-decade__years {
          left: calc(50% - 0.25rem);
        }
      }

      &:last-of-type {
        @include switch-pad(calc(calc(var(--offset) * 0.1rem) + 0.5rem), 0);

        .timeline-decade__years {
          left: calc(50% + calc(calc(calc(var(--offset) * 0.1rem) - 2rem) / 2));
        }
      }

      &:not(:first-of-type):before {
        @include switch-ltr(-3);
      }
    }

    button {
      font-size: 1.8rem;
      line-height: 1.22;

      @include fonts-loaded() {
        font-family: var(--italian);
      }
    }

    &__indicator,
    &__year {
      padding: 1rem;
      min-width: 4rem;
      @include link-underline('transparent', 'black', 'grey-dark', 1rem, calc(100% - 2rem), 1rem);

      &:before,
      &:after {
        content: none;
      }
    }

    &__indicator {
      transition: width 0.15s ease, color 0.15s ease, background-color 0.15s ease, background-size 0.075s ease-out;
      will-change: width, color, background-color, background-size;
    }

    &__years {
      visibility: hidden;
      opacity: 0;
      position: absolute;
      top: 5.5rem;
      @include central-x();
      min-width: 100%;
      max-width: calc(100vw - calc(#{$gutter-mobile} * 2));
      @include pseudo-blocks();

      &:before,
      &:after {
        height: 100%;
        width: 1.5rem;
        top: 0;
        z-index: 5;
        pointer-events: none;
      }

      &:before {
        left: 0;
        background-image: linear-gradient(270deg, var(--transparent), var(--white) 50%, var(--white));
      }

      &:after {
        right: 0;
        background-image: linear-gradient(90deg, var(--transparent), var(--white) 50%, var(--white));
      }
    }

    &__year {
      color: var(--grey-light);
      transition: color 0.15s ease, background-size 0.075s ease-out;
      will-change: color, background-size;

      &:first-of-type {
        margin-left: auto;
      }

      &:last-of-type {
        margin-right: auto;
      }

      @include rtl() {
        margin-left: 0;
        margin-right: 0;

        &:first-of-type {
          margin-right: auto;
        }

        &:last-of-type {
          margin-left: auto;
        }
      }

      &--active {
        color: var(--black);
      }
    }

    &--active {
      margin: 0 2.5rem;

      &:last-of-type {
        padding-right: calc(calc(var(--offset) * 0.1rem) - 2rem);
      }

      &:not(:first-of-type):before {
        left: -4rem;
      }

      @include rtl() {
        &:last-of-type {
          @include switch-pad(calc(calc(var(--offset) * 0.1rem) - 2rem), 0);
        }

        &:not(:first-of-type):before {
          @include switch-ltr(-4);
        }
      }

      .timeline-decade {
        &__indicator {
          background: var(--black);
          color: var(--white);
          padding: 1rem 3.5rem;
          padding: 1rem;
          min-width: 11rem;
          cursor: auto;
        }

        &__years {
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }

  &-slider {
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    @include full-page-mobile();
    @include bleed-left-mobile();
  }

  &-slides {
    display: flex;
  }

  &-slide {
    flex-shrink: 0;
    width: 100%;

    &:focus {
      outline: 0;
    }

    &__block {
      padding: 0 $gutter-mobile;

      h3 {
        display: none;
      }

      > p {
        margin-top: 2rem;

        &:first-of-type {
          margin-top: 3rem;
        }
      }
    }

    .nav--arrow {
      margin-bottom: -4rem;
    }

    &__image {
      width: 100%;
      background: var(--black);
      min-height: 30rem;
      margin-top: 4rem;
      position: relative;

      img {
        @include absolute-cover();
      }
    }
  }

  @media screen and (min-width: $portrait) {
    &-decades {
      @include full-page-portrait();
      @include bleed-left-portrait();
    }

    &-decade:first-of-type {
      padding-left: calc(#{$gutter-portrait} - 2.5rem);

      .timeline-decade__years {
        left: calc(50% + 1.75rem);
      }

      @include rtl() {
        @include switch-pad(0, calc(#{$gutter-portrait} - 2.5rem));

        .timeline-decade__years {
          left: calc(50% - 1.75rem);
        }
      }
    }

    &-slider {
      @include full-page-portrait();
      @include bleed-left-portrait();
    }

    &-slide {
      &__block {
        padding: 0 $gutter-portrait;

        h4,
        p {
          max-width: 65rem;
        }
      }

      &__image {
        min-height: 50rem;
      }
    }
  }

  @media screen and (min-width: $landscape) {
    padding: 3rem 0 12rem;

    h2 {
      @include light-underline();
      margin-bottom: 5rem;
    }

    &-wrapper {
      display: grid;
      @include grid-landscape-template();
      grid-gap: 0 $gap-landscape;
    }

    &-controls,
    &-content {
      grid-row: 1 / 2;
    }

    &-controls {
      grid-column: 11 / 13;
      margin: 0;

      @include rtl() {
        margin: 0;
      }

      &:before {
        content: none;
      }
    }

    &-decades {
      width: 100%;
      margin: 0;
      padding: 0;
      flex-direction: column;
      align-items: flex-end;
      padding: 2rem 0 !important;
      overflow-y: auto;
      height: calc(100vh - calc(var(--header) * 0.1rem)) !important;

      @include rtl() {
        margin: 0;
      }
    }

    &-decade,
    &-decade:first-of-type,
    &-decade:last-of-type {
      margin: 0;
      padding: 0;

      @include rtl() {
        margin: 0;
        padding: 0;
      }

      &.timeline-decade--active {
        padding-bottom: 1rem;
      }

      .timeline-decade__years {
        left: 50%;

        @include rtl() {
          left: 50%;
        }
      }
    }

    &-decade {
      &:not(:first-of-type) {
        margin-top: 4.5rem;

        @include rtl() {
          margin-top: 4.5rem;
        }

        &:before {
          width: auto;
          height: 4.5rem;
          border-left: 0.2rem solid var(--grey-light);
          top: -4.7rem;
          @include central-x();

          @include rtl() {
            right: auto;
            left: 50%;
          }
        }
      }

      &--active:not(:first-of-type):before {
        height: 3.5rem;
        top: -4.7rem;
      }

      &__indicator {
        min-width: 11rem;
        background-position:
          3.5rem calc(100% - 1rem),
          3.5rem calc(100% - 1rem),
          3.5rem calc(100% - 1rem);
        @include link-underline-initial(calc(100% - 7rem));

        @include rtl() {
          background-position:
            right 3.5rem top calc(100% - 1rem),
            right 3.5rem top calc(100% - 1rem),
            right 3.5rem top calc(100% - 1rem);
        }

        @include hover() {
          @include link-underline-hover(calc(100% - 7rem));
        }

        &:active {
          @include link-underline-active(calc(100% - 7rem));
        }
      }

      &__years {
        position: relative;
        top: 1rem;
        min-width: auto;
        max-width: 11rem;
        height: 0;
        overflow: hidden;
      }
    }

    &-content {
      grid-column: 1 / 10;
    }

    &-slider {
      width: calc(100% + #{$gap-landscape});
      margin: 0;
      overflow: visible;

      @include rtl() {
        margin: 0;
      }
    }

    &-slides {
      flex-direction: column;
    }

    &-slide {
      background: var(--white);
      overflow: hidden;
      @include hwa();
      transform: rotation(0.01deg);

      &:not(:last-of-type) {
        margin-bottom: 5rem;
      }

      &__block {
        padding: 0;

        h3 {
          margin-top: 2rem;
          display: block;
        }

        h4 {
          margin-top: 2rem;
        }
      }

      .nav--arrow {
        display: none;
      }

      &__image {
        min-height: 50rem;
        width: 100%;
        margin: 5rem 0 0;
      }
    }
  }
}
