a,
button {
  text-decoration: none;
  color: inherit;
  cursor: pointer;

  &[disabled] {
    color: var(--grey-light);
    border-color: var(--grey-light);

    @include hover() {
      cursor: not-allowed;
      background-color: var(--transparent);
      color: var(--grey-light);
      border-color: var(--grey-light);
    }
  }
}

.block-button {
  white-space: nowrap;
  position: relative;
  display: inline-block;
  text-transform: uppercase;
  font-size: 1.4rem;
  line-height: 1.5;
  background: var(--white);
  color: var(--black);
  letter-spacing: 0.1rem;
  padding: 1.5rem 3rem;
  transition: all 0.2s ease-out;
  will-change: background-color, color, padding;
  @include pseudo-before();

  &:before {
    width: 1.4rem;
    height: 1.4rem;
    opacity: 0.001;
    background: url('./../images/global/icon-loader--black.svg') center center no-repeat;
    background-size: 100% auto;
    @include central-y();
    @include w-c-t();
    right: 3rem;

    @include rtl() {
      @include switch-rtl(3.5);
    }
  }

  @include hover() {
    cursor: pointer;
    transition-duration: 0.05s;
    background: var(--grey-dark);
    color: var(--white);

    &:before {
      background-image: url('./../images/global/icon-loader--white.svg');
    }
  }

  &[disabled] {
    &:before,
    &:hover:before {
      background-image: url('./../images/global/icon-loader--grey.svg');
    }
  }

  &:active {
    transform: scale(0.98);
    transition: none;
  }

  @include fonts-loaded() {
    font-family: var(--roboto-medium);
  }

  &--loading {
    padding-right: 6.2rem;
    cursor: not-allowed;

    &:before {
      animation: spin 1.2s linear infinite;
      opacity: 0.999;
    }

    @include rtl() {
      padding-right: 0;
      padding-left: 6.2rem;
    }
  }

  &--outline {
    &-black,
    &-white {
      background: var(--transparent);
      border: solid 0.1rem var(--white);

      @include hover() {
        border-color: var(--grey-dark);
      }
    }

    &-black {
      border-color: var(--black);
      color: var(--black);
    }

    &-white {
      color: var(--white);
    }
  }

  &--solid {
    &-black,
    &-white {
      border: solid 0.1rem var(--white);

      @include hover() {
        border-color: var(--grey-dark);
      }
    }

    &-black {
      border-color: var(--black);
      background: var(--black);
      color: var(--white);
    }

    &-white {
      background: var(--white);
    }
  }

  &--narrow {
    padding: 1.5rem 2rem;
  }

  &--slim {
    font-size: 1.1rem;
    line-height: 1.36;
    padding: 0.8rem 1.5rem;
    @include button-padding();
  }

  &--hero {
    padding: 1rem 1.6rem;
  }

  &--shop {
    padding: 1rem 4rem 0.9rem 2rem;
    @include pseudo-after();

    &:after {
      width: 1.2rem;
      height: 1.6rem;
      background: url('./../images/global/icon-shop.svg') center center no-repeat;
      background-size: 100% auto;
      position: absolute;
      @include central-y();
      top: calc(50% - 0.1rem);
      right: 2rem;
    }

    @media screen and (min-width: $nav) {
      font-size: 1.3rem;
    }

    @include rtl() {
      @include switch-pad(4, 2);

      &:after {
        @include switch-rtl(2);
      }
    }
  }
}

.back-button {
  text-indent: -99999rem;
  position: absolute;
  top: 0.6rem;
  left: 2.1rem;
  z-index: 5;

  @include fonts-loaded() {
    font-family: var(--roboto-medium);
  }

  .icon {
    position: absolute;
    top: 0;
    left: 0;
  }

  @media screen and (min-width: $portrait) {
    padding: 1.2rem 0;
    text-indent: 0;
    width: auto;
    display: inline-flex;
    align-items: center;
    line-height: 4.4rem;
    margin-bottom: 1.5rem;
    @include link-underline('transparent', 'black', 'grey-dark', 1rem, calc(100% - 4rem), 4rem);
    top: 2.1rem;
    left: 0;

    &:before,
    &:after {
      content: none;
    }

    .product & {
      top: unset;
      left: unset;

      .icon {
        margin-bottom: 1.5rem;
      }
    }

    .icon {
      position: relative;
      margin-right: -0.4rem;

      &:before,
      &:after {
        transform: translateY(-50%);
        left: 0;
      }

      &:after,
      &:hover:after {
        background-image: url('./../images/global/icon-chevron--black.svg');
      }

      &:after {
        transform: translateY(-50%) rotate(90deg);
      }

      &:active:before {
        transform: translateY(-50%);
      }
    }

    &--black .icon {
      &:before {
        border-color: var(--black);
      }
    }

    &--white {
      @include link-underline-colours('transparent', 'white', 'grey-dark');

      .icon {
        &:before {
          border-color: var(--white);
        }

        &:after,
        &:hover:after {
          background-image: url('./../images/global/icon-chevron--white.svg');
        }
      }
    }
  }

  @include rtl() {
    @include switch-ltr(2.1);

    .icon {
      @include switch-ltr(0);
      transform: scaleX(-1);
    }

    @media screen and (min-width: $portrait) {
      @include switch-ltr(0);

      .icon {
        @include switch-mar(-0.4, 0);
      }
    }
  }
}

.slider__button {
  z-index: 5;
  position: absolute;
  bottom: -0.2rem;

  &:before,
  &:after {
    width: 4rem;
    height: 4rem;
  }

  &:before {
    background: var(--white);
    border: 0;
  }

  &:after {
    background-image: url('./../images/global/icon-chevron--black.svg');
  }

  @include hover() {
    &:after {
      background-image: url('./../images/global/icon-chevron--white.svg');
    }
  }

  &:active {
    transform: none;

    &:after {
      background-image: url('./../images/global/icon-chevron--white.svg');
    }
  }

  &--prev {
    left: -0.2rem;
  }

  &--next {
    right: -0.2rem;
    transform: rotate(180deg);

    &:active {
      transform: rotate(180deg);
    }
  }

  @include rtl() {
    &--prev {
      @include switch-ltr(-0.2);
      transform: rotate(180deg);

      &:active {
        transform: rotate(180deg);
      }
    }

    &--next {
      @include switch-rtl(-0.2);
      transform: rotate(0deg);

      &:active {
        transform: rotate(0deg);
      }
    }
  }
}

.play-button {
  position: absolute;
  bottom: 0rem;
  left: 0rem;
}
