html {
  font-size: 62.5%;
}

body,
header,
main,
footer {
  * {
    font-weight: normal;
    -webkit-font-smoothing: antialiased;
  	-moz-osx-font-smoothing: grayscale;
  }
}

body,
button,
input,
select,
textarea {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  color: var(--black);
}

body {
  font-size: 15px;
  font-size: 1.5rem;
  line-height: 1.5;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

  @include fonts-loaded() {
    font-family: var(--roboto-regular);
  }
}

.hidden {
  @include visually-hidden();
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6,
blockquote {
  font-weight: normal;
  text-transform: uppercase;

  @include fonts-loaded() {
    font-family: var(--italian);
  }
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
blockquote {
  letter-spacing: -0.025rem;
}

h1,
.h1 {
  font-size: 5.6rem;
  line-height: 1;

  @media screen and (min-width: $landscape) {
    font-size: 8.4rem;
  }
}

.h1--article,
.h2--article {
  text-transform: none;
}

.h1--article {
  font-size: 2.6rem;
  line-height: 1.3;

  @media screen and (min-width: $landscape) {
    font-size: 2.6rem;
  }

  @include fonts-loaded() {
    font-family: var(--roboto-medium);
  }
}

h2,
.h2 {
  font-size: 4rem;
  line-height: 1.1;

  @media screen and (min-width: $landscape) {
    font-size: 5.6rem;
  }
}

.h2--article {
  font-size: 1.5rem;
  line-height: 1.4;
  text-transform: uppercase;

  @media screen and (min-width: $landscape) {
    font-size: 1.5rem;
  }

  @include fonts-loaded() {
    font-family: var(--roboto-bold);
  }
}

h3,
.h3 {
  font-size: 2.8rem;
  line-height: 1.2;
}

h4,
.h4 {
  font-size: 2.2rem;
  line-height: 1.27;
}

h5,
.h5 {
  font-size: 1.8rem;
  line-height: 1.22;
}

h6,
.h6 {
  font-size: 1.5rem;
  line-height: 1.27;
}

p {
  line-height: 1.4;
}

em,
i {
  font-style: italic;
}

strong,
b {
  @include fonts-loaded() {
    font-family: var(--roboto-bold);
  }
}

sup, sub {
  font-size: 75%;
}
sup {
  vertical-align: top;
}

small,
.small,
.timestamp {
  font-size: 1rem;
  line-height: 1.6;
}

.timestamp {
  display: block;
  color: var(--grey-darkest);
  text-transform: uppercase;

  @include fonts-loaded() {
    font-family: var(--roboto-medium);
  }
}

ol {
  counter-reset: number;
  padding: 0;

  li {
    list-style: none;
    counter-increment: number;

    &:not(:last-of-type) {
      margin-bottom: 0.5rem;
    }

    &:before {
      content: counters(number, '.') '. ';
      font-weight: bold;
    }
  }

  ol {
    margin: 1rem 0;
    padding-left: 2rem;

    ol {
      counter-reset: letter;

      li {
        counter-increment: letter;

        &:before {
          content: counter(letter, lower-alpha) ') ';
        }
      }
    }
  }
}

ul {
  li {
    list-style: none;
  }
}

.diamond-list {
  li {
    position: relative;
    @include pseudo-before();
    padding-left: 2.5rem;

    &:not(:last-of-type) {
      margin-bottom: 0.5rem;
    }

    &:before {
      width: 1.5rem;
      height: 1.5rem;
      top: 0.5rem;
      left: 0;
      background: url('./../images/global/icon-umbro.svg') center center no-repeat;
      background-size: 100% auto;
    }
  }

  ul {
    padding-top: 0.5rem;

    li {
      padding-left: 1.75rem;

      &:before {
        height: 0.1rem;
        width: 0.75rem;
        background: var(--black);
        background-size: 100% 100%;
        top: 1.1rem;
      }
    }
  }

  @include rtl() {
    li {
      @include switch-pad(0, 2.5);

      &:before {
        @include switch-ltr(0);
      }

      ul li {
        @include switch-pad(0, 1.75);
      }
    }
  }
}

button {
  cursor: pointer;
}

blockquote {
  font-size: 3.2rem;
  line-height: 1.3;
}

::selection {
  color: var(--white);
  background-color: var(--grey-dark);
}

::-moz-selection {
  color: var(--white);
  background-color: var(--grey-dark);
}
