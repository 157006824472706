@mixin hwa() {
  // 3D rendering on GPU
  transform: translateZ(0);

  // fix chrome and safari flicker
  backface-visibility: hidden;
  perspective: 1000;

  // webkit-powered
  transform: translate3d(0, 0, 0);
}

@mixin debug($colour: yellow) {
  background: $colour;
  opacity: 0.5;
}

@mixin hover() {
  @media (hover: hover) {
    &:hover {
      @content;
    }
  }
}

@mixin rtl() {
  [dir="rtl"] & {
    @content;
  }
}

@mixin switch-rtl($distance: 0) {
  $formatted: $distance;

  @if not (str-index('#{$distance}', 'rem')) and not (str-index('#{$distance}', 'auto')) and not (str-index('#{$distance}', 'var(--')) {
    $formatted: #{$distance}rem;
  }

  right: auto;
  left: $formatted;
}

@mixin switch-ltr($distance: 0) {
  $formatted: $distance;

  @if not (str-index('#{$distance}', 'rem')) and not (str-index('#{$distance}', 'auto')) and not (str-index('#{$distance}', 'var(--')) {
    $formatted: #{$distance}rem;
  }

  left: auto;
  right: $formatted;
}

@mixin switch-pad($left: 0, $right: 0) {
  $formattedLeft: $left;
  $formattedRight: $right;

  @if not (str-index('#{$left}', 'rem')) and not (str-index('#{$left}', 'auto')) and not (str-index('#{$left}', 'var(--')) {
    $formattedLeft: #{$left}rem;
  }

  @if not (str-index('#{$right}', 'rem')) and not (str-index('#{$right}', 'auto')) and not (str-index('#{$right}', 'var(--')) {
    $formattedRight: #{$right}rem;
  }

  padding-left: $formattedLeft;
  padding-right: $formattedRight;
}

@mixin switch-mar($left: 0, $right: 0) {
  $formattedLeft: $left;
  $formattedRight: $right;

  @if not (str-index('#{$left}', 'rem')) and not (str-index('#{$left}', 'auto')) and not (str-index('#{$left}', 'var(--')) {
    $formattedLeft: #{$left}rem;
  }

  @if not (str-index('#{$right}', 'rem')) and not (str-index('#{$right}', 'auto')) and not (str-index('#{$right}', 'var(--')) {
    $formattedRight: #{$right}rem;
  }

  margin-left: $formattedLeft;
  margin-right: $formattedRight;
}

@mixin fonts-loaded() {
  .fonts:not([lang="el-gr"]) & {
    @content;
  }
}

@mixin greek() {
  [lang="el-gr"] & {
    @content;
  }
}

@mixin greek-loaded() {
  .fonts[lang="el-gr"] & {
    @content;
  }
}

@mixin placeholder() {
  color: var(--grey-light);

  footer & {
    color: var(--grey-dark);
  }
}

@mixin central() {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin central-y() {
  top: 50%;
  transform: translateY(-50%);
}

@mixin central-x() {
  left: 50%;
  transform: translateX(-50%);
}

@mixin pseudo-before() {
  &:before {
    content: '';
    display: block;
    position: absolute;
  }
}

@mixin pseudo-after() {
  &:after {
    content: '';
    display: block;
    position: absolute;
  }
}

@mixin pseudo-blocks() {
  &:before,
  &:after {
    content: '';
    display: block;
    position: absolute;
  }
}

@mixin visually-hidden() {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 0.1rem; width: 0.1rem;
  margin: -0.1rem; padding: 0; border: 0;
}

@mixin underlined-link() {
  text-decoration: underline;

  @include hover() {
    text-decoration: none;
  }

  &:active {
    text-decoration: underline;
  }
}

@mixin flex-col() {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@mixin w-c-o() {
  will-change: opacity;
}

@mixin w-c-t() {
  will-change: transform;
}

@mixin w-c-o-t() {
  will-change: opacity, transform;
}

@mixin overlay() {
  position: relative;
  @include pseudo-after();

  &:after {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: var(--grey-dark);
    opacity: 0.4;
  }
}

@mixin chevron($colour) {
  width: 1.5rem;
  height: 0.9rem;
  background: url('./../images/global/icon-chevron--black.svg') center center no-repeat;
  background-size: 100% auto;
  @include central-y();
  transition: all 0.3s ease-out;
  transform-origin: 50% 25%;
  will-change: transform;
}

@mixin button-padding($padding: 0.5rem) {
  position: relative;
  @include pseudo-blocks();

  &:before,
  &:after {
    position: absolute;
    height: #{$padding};
    width: 100%;
    left: 0;
  }

  &:before {
    top: -#{$padding};
  }

  &:after {
    bottom: -#{$padding};
  }
}

@mixin icon-button() {
  text-indent: -99999rem;
  width: 4.4rem;
  height: 4.4rem;
  display: block;
}

@mixin text-button() {
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  font-size: 1.3rem;

  @include fonts-loaded() {
    font-family: var(--roboto-medium);
  }
}

@mixin chevron-icon() {
  width: 3.8rem;
  height: 3.8rem;

  &:before,
  &:after {
    width: 100%;
    height: 100%;
  }

  &:before {
    border-color: var(--black);
    transition: background-color 0.2s ease-out, border-color 0.2s ease-out;
    will-change: background-color, border-color;
  }

  @include hover() {
    &:before {
      border-color: var(--transparent);
    }

    &:before {
      transition-duration: 0.05s, 0.05s;
      background: var(--grey-light);
    }
  }

  &:active:before {
    border-color: var(--transparent);
  }

  &:after {
    background-image: url('./../images/global/icon-chevron--black.svg');
    background-size: 1.2rem auto;
  }
}

@mixin light-underline($colour: 'grey-dark') {
  padding-bottom: 2rem;
  @include pseudo-before();
  position: relative;

  &:before {
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    border-bottom: 0.1rem solid var(--#{$colour});
  }
}

@mixin link-underline-colours($initialColour, $hoverColour, $activeColour) {
  $initial: var(--#{$initialColour});
  $hover: var(--#{$hoverColour});
  $active: var(--#{$activeColour});

  background-image:
    linear-gradient(#{$active}, #{$active}),
    linear-gradient(#{$hover}, #{$hover}),
    linear-gradient(#{$initial}, #{$initial});
}

@mixin link-underline-initial($size: 100%) {
  background-size:
    0 0.2rem,
    0 0.2rem,
    $size 0.2rem;
}

@mixin link-underline-hover($size: 100%) {
  background-size:
    0 0.2rem,
    $size 0.2rem,
    $size 0.2rem;
}

@mixin link-underline-active($size: 100%) {
  background-size:
    $size 0.2rem,
    $size 0.2rem,
    $size 0.2rem;
}

@mixin link-underline($initialColour, $hoverColour, $activeColour, $bottom: 0.75rem, $size: 100%, $offset: 0) {
  display: inline;
  position: relative;
  @include link-underline-colours($initialColour, $hoverColour, $activeColour);
  @include link-underline-initial($size);
  background-position:
    $offset calc(100% - #{$bottom}),
    $offset calc(100% - #{$bottom}),
    $offset calc(100% - #{$bottom});
  background-repeat: no-repeat;
  transition: background-size 0.075s ease-out;
  will-change: background-size;
  @include pseudo-blocks();

  &:before,
  &:after {
    position: relative;
    height: 1rem;
    width: 100%;
  }

  @include hover() {
    @include link-underline-hover($size);
  }

  &:active {
    @include link-underline-active($size);
  }

  @include rtl() {
    background-position:
      right $offset top calc(100% - #{$bottom}),
      right $offset top calc(100% - #{$bottom}),
      right $offset top calc(100% - #{$bottom});
  }
}

@mixin absolute-cover() {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@mixin image-underlay($opacity: 0.8, $colour: 'white') {
  color: var(--#{$colour});

  &__image {
    background: var(--black);
    @include absolute-cover();

    .lazyloaded,
    .b-loaded {
      opacity: $opacity;
    }
  }
}

@mixin square-aspect() {
  @include pseudo-before();

  &:before {
    padding-top: 100%;
    position: relative;
  }
}

@mixin grid-portrait-template() {
  grid-template-columns: repeat(6, 1fr);
}

@mixin grid-landscape-template() {
  grid-template-columns: repeat(12, 1fr);
}

@mixin grid-portrait() {
  display: grid;
  @include grid-portrait-template();
}

@mixin grid-landscape() {
  display: grid;
  @include grid-landscape-template();
}

@mixin grid() {
  @media screen and (min-width: $portrait) {
    display: grid;
    @include grid-portrait-template();
  }

  @media screen and (min-width: $landscape) {
    @include grid-landscape-template();
  }
}

@mixin grid-gaps() {
  @include grid();

  @media screen and (min-width: $portrait) {
    grid-gap: 0 $gap-mobile;
  }

  @media screen and (min-width: $landscape) {
    grid-gap: 0 $gap-landscape;
  }
}

@mixin full-page-mobile() {
  width: calc(100% + calc(#{$gutter-mobile} * 2));
}

@mixin full-page-portrait() {
  width: calc(100% + calc(#{$gutter-portrait} * 2));
}

@mixin full-page-landscape() {
  width: calc(100% + calc(#{$gutter-landscape} * 2));
}

@mixin full-page-desktop() {
  width: calc(100% + calc(#{$gutter-desktop} * 2));
}

@mixin full-page() {
  @include full-page-mobile();

  @media screen and (min-width: $portrait) {
    @include full-page-portrait();
  }

  @media screen and (min-width: $landscape) {
    @include full-page-landscape();
  }

  @media screen and (min-width: $desktop) {
    @include full-page-desktop();
  }
}

@mixin half-page-mobile() {
  width: calc(100% + #{$gutter-mobile} + calc(#{$gap-mobile} / 2));
}

@mixin half-page-portrait() {
  width: calc(100% + #{$gutter-portrait} + calc(#{$gap-mobile} / 2));
}

@mixin half-page-landscape() {
  width: calc(100% + #{$gutter-landscape} + calc(#{$gap-landscape} / 2));
}

@mixin half-page() {
  @include half-page-mobile();

  @media screen and (min-width: $portrait) {
    @include half-page-portrait();
  }

  @media screen and (min-width: $landscape) {
    @include half-page-landscape();
  }
}

@mixin half-grid-mobile() {
  width: calc(100% + #{$gutter-mobile});
}

@mixin half-grid-portrait() {
  width: calc(100% + #{$gutter-portrait});
}

@mixin half-grid-landscape() {
  width: calc(100% + #{$gutter-landscape});
}

@mixin half-grid-desktop() {
  width: calc(100% + #{$gutter-desktop});
}

@mixin half-grid() {
  @include half-grid-mobile();

  @media screen and (min-width: $portrait) {
    @include half-grid-portrait();
  }

  @media screen and (min-width: $landscape) {
    @include half-grid-landscape();
  }
}

@mixin full-grid-portrait() {
  grid-column: 1 / 7;
}

@mixin full-grid-landscape() {
  grid-column: 1 / 13;
}

@mixin full-grid() {
  @media screen and (min-width: $portrait) {
    @include full-grid-portrait();
  }

  @media screen and (min-width: $landscape) {
    @include full-grid-landscape();
  }
}

@mixin bleed-left-mobile() {
  margin-left: -#{$gutter-mobile};

  @include rtl() {
    @include switch-mar(0, -#{$gutter-mobile});
  }
}

@mixin bleed-left-portrait() {
  margin-left: -#{$gutter-portrait};

  @include rtl() {
    @include switch-mar(0, -#{$gutter-portrait});
  }
}

@mixin bleed-left-landscape() {
  margin-left: -#{$gutter-landscape};

  @include rtl() {
    @include switch-mar(0, -#{$gutter-landscape});
  }
}

@mixin bleed-left-desktop() {
  margin-left: -#{$gutter-desktop};

  @include rtl() {
    @include switch-mar(0, -#{$gutter-desktop});
  }
}

@mixin bleed-left() {
  @include bleed-left-mobile();

  @media screen and (min-width: $portrait) {
    @include bleed-left-portrait();
  }

  @media screen and (min-width: $landscape) {
    @include bleed-left-landscape();
  }

  @media screen and (min-width: $desktop) {
    @include bleed-left-desktop();
  }
}

@mixin bleed-right-mobile() {
  margin-left: calc(-1 * calc(#{$gap-mobile} / 2));

  @include rtl() {
    @include switch-mar(0, calc(-1 * calc(#{$gap-mobile} / 2)));
  }
}

@mixin bleed-right-landscape() {
  margin-left: calc(-1 * calc(#{$gap-landscape} / 2));

  @include rtl() {
    @include switch-mar(0, calc(-1 * calc(#{$gap-landscape} / 2)));
  }
}

@mixin bleed-right() {
  @include bleed-right-mobile();

  @media screen and (min-width: $landscape) {
    @include bleed-right-landscape();
  }
}

@mixin bleed-article-to-gutter() {
  @media screen and (min-width: 960px) {
    width: calc(100% + #{$offset-portrait});
    margin-left: calc(-1 * calc(#{$offset-portrait} / 2));
  }

  @media screen and (min-width: $landscape) {
    width: calc(100% + #{$offset-landscape});
    margin-left: calc(-1 * calc(#{$offset-landscape} / 2));
  }

  @media screen and (min-width: $desktop) {
    width: calc(100% + #{$offset-desktop});
    margin-left: calc(-1 * calc(#{$offset-desktop} / 2));
  }

  @media screen and (min-width: $max) {
    margin-left: -26rem;
  }

  @include rtl {
    @media screen and (min-width: 960px) {
      @include switch-mar(0, calc(-1 * calc(#{$offset-portrait} / 2)));
    }

    @media screen and (min-width: $landscape) {
      @include switch-mar(0, calc(-1 * calc(#{$offset-landscape} / 2)));
    }

    @media screen and (min-width: $desktop) {
      @include switch-mar(0, calc(-1 * calc(#{$offset-desktop} / 2)));
    }

    @media screen and (min-width: $max) {
      @include switch-mar(0, -26rem);
    }
  }
}

@mixin bleed-article-to-full() {
  @media screen and (min-width: 960px) {
    width: calc(100% + calc(#{$gutter-portrait} * 2) + #{$offset-portrait} - calc(var(--sb) * 0.1rem));
    margin-left: calc(-1 * calc(#{$gutter-portrait} + calc(#{$offset-portrait} / 2)) + calc(var(--sb) * 0.05rem));
  }

  @media screen and (min-width: $landscape) {
    width: calc(100% + calc(#{$gutter-landscape} * 2) + #{$offset-landscape} - calc(var(--sb) * 0.1rem));
    margin-left: calc(-1 * calc(#{$gutter-landscape} + calc(#{$offset-landscape} / 2)) + calc(var(--sb) * 0.05rem));
  }

  @media screen and (min-width: $desktop) {
    width: calc(100% + calc(#{$gutter-desktop} * 2) + #{$offset-desktop} - calc(var(--sb) * 0.1rem));
    margin-left: calc(-1 * calc(#{$gutter-desktop} + calc(#{$offset-desktop} / 2)) + calc(var(--sb) * 0.05rem));
  }

  @media screen and (min-width: $max) {
    margin-left: -38rem;
  }

  @include rtl {
    @media screen and (min-width: 960px) {
      @include switch-mar(0, calc(-1 * calc(#{$gutter-portrait} + calc(#{$offset-portrait} / 2)) + calc(var(--sb) * 0.05rem)));
    }

    @media screen and (min-width: $landscape) {
      @include switch-mar(0, calc(-1 * calc(#{$gutter-landscape} + calc(#{$offset-landscape} / 2)) + calc(var(--sb) * 0.05rem)));
    }

    @media screen and (min-width: $desktop) {
      @include switch-mar(0, calc(-1 * calc(#{$gutter-desktop} + calc(#{$offset-desktop} / 2)) + calc(var(--sb) * 0.05rem)));
    }

    @media screen and (min-width: $max) {
      @include switch-mar(0, -38rem);
    }
  }
}
