.news {
  margin-bottom: 8rem;

  @media screen and (min-width: $landscape) {
    margin-bottom: 12rem;
  }

  &:last-of-type {
    margin-bottom: 8rem;

    @media screen and (min-width: $landscape) {
      margin-bottom: 12rem;
    }
  }

  &__header {
    @include light-underline();
    padding-bottom: 1rem;
    margin-bottom: 4rem;

    .h5 {
      display: block;
      margin-bottom: 2rem;
    }

    .h1--article {
      margin-bottom: 1.5rem;
    }

    .timestamp {
      margin-bottom: 3rem;
    }
  }

  &__picture,
  &__quote,
  &__video,
  &__slider {
    margin: 4rem 0;
  }

  &__picture,
  &__slider,
  &__video {
    background: var(--grey-light);
  }

  &__picture--pair,
  &__slider {
    max-width: $max;
    @include full-page();
    @include bleed-left();
    @include bleed-article-to-full();
  }

  &__picture {
    .news__image {
      height: 30rem;
    }
  }

  &__image {
    position: relative;
    background: var(--black);

    img {
      @include absolute-cover();
    }
  }

  &__slider,
  &__video {
    .news__image {
      height: 35rem;
    }
  }

  &__video {
    max-width: calc(#{$max} - calc(#{$gutter-desktop} * 2));
    @include bleed-article-to-gutter();
    position: relative;
  }

  &__iframe {
    opacity: 0.001;
    visibility: hidden;
    @include absolute-cover();

    iframe {
      width: 100%;
      height: 100%;
      pointer-events: auto;
    }
  }

  @media screen and (min-width: $portrait) {
    &__header {
      padding-top: 2rem;
    }

    &__picture,
    &__quote,
    &__video,
    &__slider {
      margin-top: 6rem;
      margin-bottom: 6rem;
    }

    &__picture,
    &__slider {
      .news__image {
        height: 40rem;
      }
    }

    &__video {
      .news__image {
        height: 50rem;
      }
    }
  }

  @media screen and (min-width: $landscape) {
    &__header {
      padding-top: 3rem;
      margin-bottom: 5rem;
    }

    &__picture,
    &__quote,
    &__video,
    &__slider {
      margin-top: 8rem;
      margin-bottom: 8rem;
    }

    &__picture {
      .news__image {
        height: 55rem;
      }

      &--pair {
        .slider__slide {
          width: 50%;
        }
      }
    }

    &__slider {
      .news__image {
        height: 50rem;
      }
    }

    &__video {
      .news__image {
        height: 65rem;
      }
    }
  }
}
