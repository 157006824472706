.quote {
  @include image-underlay(0.75);

  .inner {
    position: relative;
  }

  &__block {
    padding: 3rem;
    position: relative;
    min-height: 40rem;
    @include flex-col();
    justify-content: center;
    text-align: center;
  }

  h2 {
    font-size: 3.2rem;
    line-height: 1.3;
  }

  @media screen and (min-width: $portrait) {
    &__block {
      min-height: 45rem;
    }

    h2 {
      font-size: 5.6rem;
      line-height: 1.07;
    }
  }

  @media screen and (min-width: $landscape) {
    &__block {
      min-height: 50rem;
      padding: 3rem calc(calc(#{$col-landscape} * 2) + calc(#{$gap-landscape} * 2));
    }
  }

  @media screen and (min-width: $max) {
    &__block {
      padding: 3rem 24.6667rem;
    }
  }
}
